import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { CDN_URL, MIN_AMOUNT, TRANSACTION_TYPES, VALIDATION_MESSAGES } from '../../helpers/constants.helper';
import { isValidEmail, nameRand } from '../../helpers/utils.helper';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useCustomer } from '../../hooks/useCustomer';
import paymentService from '../../services/requests/PaymentService';
import { Project } from '../../services/requests/ProjectService';
import TransactionTypeSelector from './selectors/TransactionTypeSelector';
import MoneySizesSelector from './selectors/MoneySizesSelector';
import CustomAmountInput from './selectors/CustomAmountInput';
import FormField from './selectors/FormField';
import TermsAndConditionsField from './selectors/TermsAndConditionsField';
import { ChevronRight } from 'react-bootstrap-icons';
import HideDisplayNameField from './selectors/HideDisplayNameField';
import { ProjectCardSmall } from '../../components/Project/ProjectCardSmall';

interface PaymentComponentProps {
  project?: Project;
}

const PaymentComponent: React.FC<PaymentComponentProps> = ({ project }) => {
  const { customer, moneySizes, isMoneySizesEnabled, isOneTimePaymentEnabled } = useCustomer();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const [amount, setAmount] = useState<number>(moneySizes[0]);
  const [transactionTypeId, setTransactionTypeId] = useState<number>(TRANSACTION_TYPES[0].value);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    notes: '',
    termsAndConditions: true,
    hideDisplayName: false,
  });
  const [validation, setValidation] = useState({
    firstName: { isTouched: false, isValid: false, message: '' },
    lastName: { isTouched: false, isValid: false, message: '' },
    email: { isTouched: false, isValid: false, message: '' },
    amount: { isTouched: true, isValid: true, message: '' },
    termsAndConditions: { isTouched: false, isValid: false, message: '' },
  });

  useEffect(() => {
    onFormChange('firstName', nameRand());
    onFormChange('lastName', nameRand());
    onFormChange('email', nameRand(true));
    onFormChange('amount', moneySizes[0]);
    onFormChange('termsAndConditions', 'on', true);
  }, []);

  const validateField = (name: string, value: any, checked?: boolean) => {
    let isValid = false;
    let message = '';

    const isRequired = t(VALIDATION_MESSAGES.REQUIRED);
    switch (name) {
      case 'firstName':
      case 'lastName':
        isValid = value.trim() !== '';
        message = isValid ? '' : isRequired;
        break;
      case 'email':
        isValid = isValidEmail(value);
        message = isValid ? '' : value.trim() === '' ? isRequired : t(VALIDATION_MESSAGES.INVALID_EMAIL);
        break;
      case 'termsAndConditions':
        isValid = checked || false;
        message = isValid ? '' : t(VALIDATION_MESSAGES.MUST_ACCEPT_TERMS_AND_CONDITIONS);
        break;
      case 'amount':
        isValid = +value >= MIN_AMOUNT;
        message = isValid ? '' : value ? t(VALIDATION_MESSAGES.MUST_BE_MIN_VALUE) : isRequired;
        break;
      default:
        break;
    }

    setValidation((prevValidation) => ({
      ...prevValidation,
      [name]: { isTouched: true, isValid, message },
    }));
  };
  const handleFormChange = ({ target: { value, name, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    onFormChange(name, value, checked);
  };

  const onFormChange = (name: string, value: string | number, checked?: boolean) => {
    if (!['notes', 'hideDisplayName'].includes(name)) {
      validateField(name, value, checked);
    }

    setForm((prevForm) => ({
      ...prevForm,
      [name]: ['termsAndConditions', 'hideDisplayName'].includes(name) ? checked : value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    if (!Object.values(validation).every((field) => field.isValid)) {
      return;
    }

    const domain = customer?.domain;
    if (!domain) {
      return;
    }

    const { email, firstName, lastName, notes, hideDisplayName } = form;

    setSubmitting(true);

    const paymentAuthorization = await paymentService.authorizeTransaction({
      amount: amount * 100,
      domain,
      email,
      firstName,
      lastName,
      transactionTypeId,
      notes,
      hideDisplayName,
      projectId: project?.projectId,
      nextUrl: window.location.href,
    });

    setSubmitting(false);

    if (!paymentAuthorization) {
      toast.error(`Payment could not be processed. Please try again later`);
      return;
    }

    window.location.href = paymentAuthorization.formUrl;
  };

  return (
    <>
      {project && <Col lg={12}>
        <ProjectCardSmall project={project}/>
      </Col>}

      {!project && !isOneTimePaymentEnabled && <TransactionTypeSelector transactionTypeId={transactionTypeId} onSelect={(id) => {
        setTransactionTypeId(id);
      }}/>
      }
      <Form onSubmit={handleSubmit}>
        <Col>
          {isMoneySizesEnabled && (
            <MoneySizesSelector
              selectedAmount={amount}
              onSelect={(amount) => {
                validateField('amount', amount);
                setAmount(amount);
              }}
            />
          )}

          <CustomAmountInput amount={amount} onChange={(amount) => {
            validateField('amount', amount);
            setAmount(amount);
          }} validation={validation.amount}/>

          <Row>
            <Col lg={6}>
              <FormField
                name="firstName"
                label={t('firstName')}
                type="text"
                value={form.firstName}
                onChange={handleFormChange}
                validation={validation.firstName}
              />
            </Col>
            <Col lg={6}>
              <FormField
                name="lastName"
                label={t('lastName')}
                type="text"
                value={form.lastName}
                onChange={handleFormChange}
                validation={validation.lastName}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormField
                name="email"
                label={t('email')}
                type="email"
                value={form.email}
                onChange={handleFormChange}
                validation={validation.email}
              />
            </Col>
            <Col lg={12}>
              <FormField
                name="notes"
                label={t('notes')}
                type="textarea"
                value={form.notes}
                onChange={handleFormChange}
                validation={{ isTouched: true, isValid: true, message: '' }}
              />
            </Col>

            <Col lg={12}>
              <HideDisplayNameField
                checked={form.hideDisplayName}
                onChange={handleFormChange}
                validation={{ isTouched: true, isValid: true, message: '' }}
              />
            </Col>

            <Col lg={12}>
              <hr/>
              <TermsAndConditionsField
                checked={form.termsAndConditions}
                onChange={handleFormChange}
                validation={validation.termsAndConditions}
              />
            </Col>

          </Row>

          <Row>
            <Col lg={12}>
              <Button disabled={!Object.values(validation).every((field) => field.isValid) || submitting}
                      className="mt-3 d-flex align-items-center justify-content-center" size={'lg'} block={true}
                      color="warning" type="submit">
                <b className={'text-uppercase'}><strong>{t('submit')}</strong></b><ChevronRight/>
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </>
  );
};

export default PaymentComponent;
