import { BaseService } from './BaseService';

export interface PaymentSession {
  projectId: number;
  transactionId: number;
  state: string;
  isSuccess: boolean;
  receipt?: string;
}

export class SessionService extends BaseService {
  async getPaymentSession(sessionId: string): Promise<PaymentSession | null> {
    return this.get<PaymentSession>(`/sessions/${sessionId}`);
  }

}

export const sessionService = new SessionService();
