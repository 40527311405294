import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface HideDisplayNameFieldProps {
  checked: boolean;
  onChange: (e: any) => void;
  validation: { isTouched: boolean; isValid: boolean; message: string };
}

const HideDisplayNameField: React.FC<HideDisplayNameFieldProps> = ({ checked, onChange, validation }) => {
  const { t } = useTranslation();
  return (
    <FormGroup check>
      <Label check>
        <Input type="checkbox" name={"hideDisplayName"} checked={checked} onChange={onChange} invalid={!validation.isValid && validation.isTouched} />
        {t('hideDisplayName')}
      </Label>
    </FormGroup>
  );
};

export default HideDisplayNameField;
