import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TRANSACTION_TYPES, TransactionType } from '../../../helpers/constants.helper';
import { NavStyled } from '../../../Styles';

interface TransactionTypeSelectorProps {
  transactionTypeId: number;
  onSelect: (value: number) => void;
}

const TransactionTypeSelector: React.FC<TransactionTypeSelectorProps> = ({ transactionTypeId, onSelect }) => {
  const { t } = useTranslation();

  return (
      <NavStyled justified pills>
        {TRANSACTION_TYPES.map(({ label, value }: TransactionType) => (
          <NavItem key={value}>
            <NavLink onClick={() => onSelect(value)} active={transactionTypeId === value}>
              {t(label)}
            </NavLink>
          </NavItem>
        ))}
      </NavStyled>
  );
};

export default TransactionTypeSelector;
