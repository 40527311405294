import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useProjects } from '../hooks/useProjects';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((x) => x);

  const {projectMap} = useProjects();

  return (
    <div className={"bg-light pe-3 ps-3 pt-2 pb-2 rounded mb-3"}>
      <Breadcrumb listClassName={"mb-0"}>
        <BreadcrumbItem>
          <Link to="/">{t('home')}</Link>
        </BreadcrumbItem>
        {pathNames.map((value, index) => {
          const to = `/${pathNames.slice(0, index + 1).join('/')}`;

          return (
            <BreadcrumbItem key={to}>
              {index === pathNames.length - 1 ? (
                projectMap[value] ?? t(value) ?? value
              ) : (
                <Link to={to}>{t(value)}</Link>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
