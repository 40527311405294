import React from 'react';
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../../helpers/utils.helper';
import { MIN_AMOUNT } from '../../../helpers/constants.helper';

interface CustomAmountInputProps {
  amount: number;
  onChange: (value: number) => void;
  validation: { isTouched: boolean; isValid: boolean; message: string };
}

const CustomAmountInput: React.FC<CustomAmountInputProps> = ({ amount, onChange, validation }) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Label for="amount">{t('amount')}</Label>
      <InputGroup>
        <Input
          min={MIN_AMOUNT}
          step={100}
          value={amount}
          onChange={(e) => onChange(+e.target.value)}
          invalid={!validation.isValid && validation.isTouched}
          type="number"
          name="amount"
          id="amount"
        />
        <InputGroupText>
          <b><strong>{Currency.AMD}</strong></b>
        </InputGroupText>
        {!validation.isValid && validation.isTouched &&
          <FormFeedback>{validation.message.replace('{{value}}', `${MIN_AMOUNT}`)} {Currency.AMD}</FormFeedback>}
      </InputGroup>
    </FormGroup>
  );
};

export default CustomAmountInput;
