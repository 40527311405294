import { Col, Row } from 'reactstrap';
import { CDN_URL } from '../../helpers/constants.helper';
import React from 'react';
import { Project } from '../../services/requests/ProjectService';

interface ProjectCardSmallProps {
  project: Project;
}

export const ProjectCardSmall = ({ project }: ProjectCardSmallProps) => {
  return <div className={'mb-3'}>
    <Row>
      <Col lg={3}>
        <img alt={project.name} className={'img-fluid rounded'} src={`${CDN_URL}/${project.image}`}/>
      </Col>
      <Col lg={9}>
        <h1 className={'fs-6'}><b><strong>{project.name}</strong></b></h1>
        <div style={{fontSize: "0.8rem"}}>{project.shortTextDescription}</div>
      </Col>
    </Row>
  </div>;
};

