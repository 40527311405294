import styled from 'styled-components';
import { Nav } from 'reactstrap';

export const NavStyled = styled(Nav)`
    .nav-item:not(:last-child) {
        margin-right: 0.50rem;
        margin-bottom: 0.50rem;
    }

    .nav-link {
        color: #000;
        cursor: pointer;
        border: 2px solid #ffc107;
        font-weight: 900;

        &:hover {
            background-color: #ffc107;
        }

        &.active {
            background-color: #ffc107;
            color: #000;
        }
    }
`;
