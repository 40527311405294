import { BaseService } from './BaseService';

export interface Customer {
  name: string;
  domain: string;
  attributes: Attributes;
}

export interface Attributes {
  name: string;
  intro: string;
  description: any;
  privacyPolicy: any;
  logoPath: string;
  moneySizes: string;
  isOneTimePayment: 'false' | 'true';
  isMoneySizesEnabled: 'false' | 'true';
  isInitiativesEnabled: 'false' | 'true';
  initiatives: string;
}

export class CustomerService extends BaseService {
  async getCustomer(clientId: string): Promise<Customer | null> {
    return this.get<Customer>(`/customers/${clientId}`);
  }

}

export const customerService = new CustomerService();
