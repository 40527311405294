export const COMPANY_NAME = 'HyeCloud.dev';

export const TRANSACTION_TYPE_ONE_TIME = 2;
export const TRANSACTION_TYPE_PERIODIC = 1;

export interface TransactionType {
  label: string;
  value: number;
}
export const TRANSACTION_TYPES: TransactionType[] = [
  {
    label: 'oneTime',
    value: TRANSACTION_TYPE_ONE_TIME,
  },
  {
    label: 'monthly',
    value: TRANSACTION_TYPE_PERIODIC,
  },
];
export const MIN_AMOUNT = 100;

export const VALIDATION_MESSAGES = {
  REQUIRED: 'This field is required',
  INVALID_EMAIL: 'Please enter a valid email address',
  MUST_ACCEPT_TERMS_AND_CONDITIONS: 'You must accept the terms and conditions',
  MUST_BE_MIN_VALUE: `The value must be at least {{value}}`,
};



export const CDN_URL = 'https://cdn.hyecloud.io';

export const PROJECTS_PER_PAGE = 8;
