import axios, { AxiosResponse } from 'axios';

export const apiBase = axios.create({
  baseURL: '/api/api/v3',
});

export class BaseService {
  protected async get<T>(url: string, params?: any): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await apiBase.get(url, { params });
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  protected async post<T>(url: string, data: any): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await apiBase.post(url, data);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
