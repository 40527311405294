import Copyright from './copyright';
import React from 'react';
import { FooterBlock } from './styles';
import { useCustomer } from '../hooks/useCustomer';

const Footer = () => {
  const { customer } = useCustomer();
  return <FooterBlock>
    <Copyright customer={customer}/>
  </FooterBlock>;
};

export default Footer;
