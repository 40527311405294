import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface TermsAndConditionsFieldProps {
  checked: boolean;
  onChange: (e: any) => void;
  validation: { isTouched: boolean; isValid: boolean; message: string };
}

const TermsAndConditionsField: React.FC<TermsAndConditionsFieldProps> = ({ checked, onChange, validation }) => {
  const { t } = useTranslation();
  return (
    <FormGroup check>
      <Label check>
        <Input type="checkbox" name={"termsAndConditions"} checked={checked} onChange={onChange} invalid={!validation.isValid && validation.isTouched} />
        {t('agreeTermsAndConditions')}
      </Label>{' '}
      <Link target="_blank" to="/terms-and-conditions">
        {t('read')}
      </Link>
    </FormGroup>
  );
};

export default TermsAndConditionsField;
