import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

interface FormFieldProps {
  name: string;
  label: string;
  type: any;
  value: string;
  onChange: (e: any) => void;
  validation: { isTouched: boolean; isValid: boolean; message: string };
}

const FormField: React.FC<FormFieldProps> = ({ name, label, type, value, onChange, validation }) => (
  <FormGroup>
    <Label for={name}>{label}</Label>
    <Input
      onChange={onChange}
      value={value}
      type={type}
      name={name}
      id={name}
      invalid={!validation.isValid && validation.isTouched}
    />
    {!validation.isValid && validation.isTouched && <FormFeedback>{validation.message}</FormFeedback>}
  </FormGroup>
);

export default FormField;
