import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './root.css';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import i18n from './services/i18n';
import { I18nextProvider } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import LayoutV2 from './containers/layout2';
import NotFound from './pages/NotFound';
import { CustomerProvider } from './hooks/useCustomer';
import { ProjectsProvider } from './hooks/useProjects';
import Loading from './components/Loading';

const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%; /* Make sure the body takes the full height */
        margin: 0;
    }

    /* The wrapper that contains your entire page content */
    #root, .page-container {
        display: flex;
        flex-direction: column;
        min-height: 100vh; /* Ensure the container takes the full height of the viewport */
    }

    /* The main content of the page */
    .content-wrap {
        flex: 1;
    }

    /* Footer styling */
    footer {
        position: relative;
        bottom: 0;
        width: 100%;
    }

    /* Heartbeat animation */
    @keyframes heartbeat {
        0%, 100% {
            transform: scale(1);
        }
        25% {
            transform: scale(1.3);
        }
        50% {
            transform: scale(0.9);
        }
        75% {
            transform: scale(1.3);
        }
    }

    /* Initial state for the heart icon */
    .heart-icon {
        transition: transform 0.2s ease-in-out;
    }

    /* Apply heartbeat animation on button hover */
    .heart-btn:hover .heart-icon {
        animation: heartbeat 1s ease-in-out infinite;
    }

    .alert-dismissible .close {
        padding: 0.63rem 1.25rem;
    }

    .form-control:focus {
        border-color: #ffc008;
        box-shadow: 0 0 0 .25rem rgb(255 192 8 / 53%);
    }
`;

const container = document.getElementById('root');

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutV2/>,
    children: [
      {
        index: true,
        path: '/',
        lazy: () => import('./pages/Home').then(module => ({ Component: module.default })),
      },
      {
        path: 'about',
        lazy: () => import('./pages/About').then(module => ({ Component: module.default })),
      },
      {
        path: 'terms-and-conditions',
        lazy: () => import('./pages/TermsAndConditions').then(module => ({ Component: module.default })),
      },
      {
        path: 'projects',
        lazy: () => import('./pages/Projects').then(module => ({ Component: module.default })),
      },
      {
        path: 'projects/:alias',
        lazy: () => import('./pages/Project/Project').then(module => ({ Component: module.default })),
      },
      {
        path: 'contacts',
        lazy: () => import('./pages/Contacts').then(module => ({ Component: module.default })),
      },
      {
        path: 'unsubscribe',
        lazy: () => import('./pages/Unsubscribe').then(module => ({ Component: module.default })),
      },
      {
        path: '*',
        element: <NotFound/>,
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<Loading style={{ height: '100vh' }}/>}/>;
}

if (container) {
  const root = createRoot(container);

  root.render(
    <>
      <CustomerProvider>
        <I18nextProvider i18n={i18n}>
          <GlobalStyle/>
          <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            position={'top-left'}
          />
          <ProjectsProvider>
            <App/>
          </ProjectsProvider>
        </I18nextProvider>
      </CustomerProvider>
    </>,
  );
} else {
  console.error('Root container not found');
}
