import { uniqueNamesGenerator, starWars, adjectives } from 'unique-names-generator';

export const isValidEmail = (email: string) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};
export const getMoneySizes = (str: string | undefined): number[] => {
  if (!str) {
    return [0];
  }
  return str.split(',').map((value: string) => +value);
};

export const getNumeric = (str?: string | null): number => {
  if (!str) {
    return 10000;
  }
  return !isNaN(parseFloat(str)) ? parseFloat(str) * 100 : 10000;
};

export enum Currency {
  USD = '$',
  AMD = '֏',
}

export const toCommaNumber = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const nameRand = (isEmail?: boolean) => {

  if (isEmail) {
    return uniqueNamesGenerator({
      dictionaries: [adjectives]
    }) + '@gmail.com';
  }

    return uniqueNamesGenerator({
    dictionaries: [starWars]
  });
};
