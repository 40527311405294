import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const NotFoundContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ErrorTemplate = styled.div`
  padding: 20px;
`;

const ErrorTitle = styled.h1`
  font-size: 6rem;
  margin-bottom: 1rem;
`;

const ErrorSubtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ErrorDetails = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;

const ErrorActions = styled.div`
  .btn {
    margin: 0.5rem;
  }
`;

const NotFound: React.FC = () => {

  const {t} = useTranslation();

  return (
    <NotFoundContainer>
      <Row>
        <Col>
          <ErrorTemplate>
            <ErrorTitle>404</ErrorTitle>
            <ErrorSubtitle>Page Not Found</ErrorSubtitle>
            <ErrorDetails>
              {t('notFound')}
            </ErrorDetails>
            <ErrorActions>
              <Button color="primary" tag={Link} to="/">
                {t('home')}
              </Button>
              <Button color="secondary" tag={Link} to="/contacts">
                {t('contacts')}
              </Button>
            </ErrorActions>
          </ErrorTemplate>
        </Col>
      </Row>
    </NotFoundContainer>
  );
};

export default NotFound;
